import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Baa = () => {
  const headingbanner = {
    title: `BAA Platform`,
    content: `A Platform for Buying Agents`,
  };

  const data = {
    images: ["baa.webp", "baa-2.webp"],
    firstAlt: "UI UX Newsfeed screen to share announcements, events, etc",
    firstTitle: "Manage Newsfeed",
    secondAlt: "Agents Directory to showcase their details",
    secondTitle: "Agent’s Directory",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["UX Consultation", "UI UX Designing", "Wordpress Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `This platform was formed to fulfil the need of Buying Agents to get together formally and work to improve their standards and growth avenues.`,
          `Earlier, these agents didn’t have access to an online platform to communicate and support each other. It was possible only through events, emails, meetings, etc. `,
          `The main challenge was to make the platform simpler for users of all age groups, increase engagement amongst members and encourage collaboration.`,
          `We had to make a platform for members to search, explore the catalogue and set up a spam-free communication channel.`
        ],
      },
      {
        title: "Solution",
        content: [
          `Our primary goal was to remove all the obstacles by making it much simpler and more intuitive.`,
          `We created a simple platform while also offering a detailed directory of the members and also increased engagement using notifications, alerts, and messages.`,
          `The main aim was to enable easy actions for the admin to manage the members and portal while making it easy and subtle to the eyes. We also simplified the registrations and payments process for a better platform experience. `
        ],
      },
    ],
    image: ["baa-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: `Hire Candidates`,
    para: [
      `We provided a simplified interface for hiring the required candidate for the export needs. The platform was kept simple for better accessibility. `,
    ],
    content: {
      imageAlt: 'UI Screen of All Applicants list for same designation',
      imageTitle: 'All Applicants List',
      image: ["baa-projectone.webp"],
      text: `We also created a dedicated directory section for the users to showcase their products to the community for increased product reach.`,
    },
    content2: {
      imageAlt: 'Company Directory with details and product catalogue',
      imageTitle: 'Directory',
      image: ["baa-projectone-2.webp"],
      text: `This platform offers a holistic solutions to the buying agents. It facilitates smooth communication, community engagements, etc. to support each other on export trading.`,
    },
  };

  const projectData2 = {
    title: `Manage Jobs`,
    para: [
      `This interface lets the admin post and manage the jobs while also utilising the filter option that we added on the top for better assistance and improved productivity. `,
    ],
    content: {
      image: ["baa-projecttwo.webp"],
      imageAlt: 'UI screen to manage jobs positions',
      imageTitle: 'Manage Jobs',
    },
  };

  const projectData3 = {
    title: `Newsfeed`,
    para: [
      `The platform was made to assist the buying agents with regard to export trading, so it was important to inform everyone about the latest international news to keep the agents updated.`,
      `With this perspective, we created a news feed interface that posts news, events and the latest happenings for improved engagement.`
    ],
    content: {
      image: [
        "baa-projectthree.webp",
        "baa-projectthree-2.webp",
      ],
      firstAlt: 'UI screen to view latest job opportunities',
      firstTitle: 'Latest Job Opportunities',
      secondAlt: 'Post about latest announcement, events and more',
      secondTitle: 'Manage Newsfeed',
    },
  };

  const conclusionData = [
    {
      para: [
        `The association was formed to connect the buying agents in order to support export trading. But they did not have a platform through which they could communicate and run their operations smoothly. `,
        `With our solutions, we created a simple yet informative platform for the buying agents of all age groups wherein we simplified the payments process, user experience in the platform and other methods to increase overall engagement in the platform. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={29} />
      </div>
    </Layout>
  );
};

export default Baa;

export const Head = () => (
  <Seo title="BAA- Buying Agent Platform Case Study | Octet Design Studio" description="We did ideation, UI UX designing, and frontend development for BAA - a merchandising solutions platform." />
)